import * as React from "react"
import { env } from "../../config"

declare global {
  interface Window {
    onMapsLoaded: () => void
    google: typeof google
  }
}

let googleMapsLoaded = false

function loadGoogleMaps(callbackName: string) {
  const GOOGLE_MAPS_API_KEY = env("GOOGLE_MAPS_API_KEY")

  if (googleMapsLoaded === false) {
    googleMapsLoaded = true

    const googleMapsScript = document.createElement("script")
    googleMapsScript.setAttribute("id", "google-maps")
    googleMapsScript.setAttribute(
      "src",
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=en&callback=${callbackName}&loading=async`,
    )
    document.head.appendChild(googleMapsScript)
  }
}

export function useGoogleMaps() {
  const [mapsLoaded, setMapsLoaded] = React.useState(() => googleMapsLoaded)
  const [google, setGoogle] = React.useState<typeof window.google | null>(() =>
    googleMapsLoaded && window.google ? window.google : null,
  )
  const onMapsLoaded = () => {
    setMapsLoaded(true)
    setGoogle(window.google)
  }

  const loadMaps = () => {
    if (mapsLoaded === true) {
      return
    }

    loadGoogleMaps("onMapsLoaded")
  }

  React.useEffect(() => {
    window.onMapsLoaded = onMapsLoaded

    if (window.google && !google) {
      setGoogle(window.google)
      setMapsLoaded(true)
    }

    return () => {
      window.onMapsLoaded = () => {}
    }
  }, [google])

  return {
    mapsLoaded,
    loadMaps,
    google,
  }
}
