"use client"
import * as React from "react"

import { GoArrowUpRight, GoArrowDownRight } from "react-icons/go"

import classNames from "classnames"

import type { GoogleAddressData } from "./location"

type PlaceInputAutocompleteProps = {
  value: GoogleAddressData | null
  onChange: (value: GoogleAddressData | null) => void
  onFocus?: () => void
  placeholder?: string
  type?: "pickup" | "dropoff"
  id?: string
  required?: boolean
}

const generateFakeAddressValue = (address: string) => {
  return {
    formatted_address: address,
    city: "",
    state_code: "",
    lat: 0,
    lng: 0,
    zip: null,
  }
}

export const PlaceInputPlaceholder = /* @__PURE__ */ React.forwardRef<
  HTMLInputElement,
  PlaceInputAutocompleteProps
>(function PlaceInputAutocomplete(props: PlaceInputAutocompleteProps, ref) {
  return (
    <div>
      <div>
        <label
          className={classNames(
            "flex uppercase text-gray16 text-sm lg:text-base",
          )}
          htmlFor={props.id}
        >
          <span className="pr-1">
            {props.type === "pickup" ? (
              <GoArrowUpRight size={24} className="text-gold" />
            ) : (
              <GoArrowDownRight size={24} className="text-gold" />
            )}
          </span>
          {props.placeholder}{" "}
          {props.required && <span className="text-gray16">*</span>}
        </label>
        <input
          className="w-full xl:min-w-[272px] p-4 mt-4 focus:outline-none text-base bg-gray50 h-[56] border-b text-neutral-600 border-black"
          ref={ref}
          value={props.value == null ? "" : props.value.formatted_address}
          onChange={(event) => {
            const address = event.target.value
            if (address == "") {
              props.onChange(null)
            } else {
              props.onChange(generateFakeAddressValue(address))
            }
          }}
          onFocus={props.onFocus}
        />
      </div>
    </div>
  )
})

export function includesAll<T>(array: T[], elements: T[]): boolean {
  let result = true

  for (const elem of elements) {
    if (!array.includes(elem)) {
      result = false
      break
    }
  }

  return result
}
