import React from "react"
import {
  SbBlokData,
  storyblokEditable,
  renderRichText,
  ISbRichtext,
} from "@storyblok/react"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import useCTARoute from "../../util/useCTARoute"

export interface IQuoteBlock extends SbBlokData {
  heading: string
  content: ISbRichtext | string
  buttonLabel: string
  buttonLink?: string
  bgColor?: string
}

export const QuoteBlock = ({
  blok,
  host,
}: {
  blok: IQuoteBlock
  host?: Record<string, string>
}) => {
  const appHost = host?.app || process.env.NEXT_PUBLIC_APP_HOST || ""
  const {
    heading,
    content,
    buttonLabel,
    buttonLink = `${appHost}/shipments/new`,
    bgColor = "bg-orange1",
  } = blok
  const { route } = useCTARoute()

  const renderedContent =
    typeof content === "string" ? content : renderRichText(content) || ""

  return (
    <section
      className={`w-full md:flex border-y border-gray-100 flex-col items-center px-4 md:px-10 py-32 space-y-10 ${bgColor}`}
      {...storyblokEditable(blok)}
    >
      <h3 className="text-2xl md:text-4xl text-blue7 text-center font-montserrat font-medium">
        {heading}
      </h3>
      <div
        className="text-center text-base md:text-2xl lg:max-w-4xl"
        dangerouslySetInnerHTML={{ __html: renderedContent }}
      />
      <PrimaryButton
        onClick={() => window.location.assign(buttonLink || route)}
        className="max-h-[74px] flex justify-center items-center rounded-md mx-auto"
      >
        {buttonLabel}
      </PrimaryButton>
    </section>
  )
}

export default QuoteBlock
